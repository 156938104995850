.Room {
  border: 1px solid black;
  border-radius: 2px;
  position: absolute;
  width: 100%;
  height: 100%;
  /*pointer-events: none;*/
}

.col0 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.row {
  flex-grow: 1;
  padding: 0.5vw;
  display: flex;
  flex-direction: row;
  height: 100%;
}

.row2 {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-basis: 100px;
  flex-grow: 2;
  flex-shrink: 0;
}

.roomBox.scrollable {
  position: relative;
  padding: 0;
}

.roomBoxScrollableContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0.5vw;
  overflow-y: scroll;
}

.roomBox.scrollable h1,
.roomBox.scrollable h2,
.roomBox.scrollable h3,
.roomBox.scrollable h4 {
  white-space: normal;
}

.col1 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-self: stretch;
}

.col2 {
  display: flex;
  flex-basis: 30%;
  flex-direction: column;
}

.sToothChart {
  position: relative;
  overflow: hidden;
  -webkit-flex-basis: 70%;
  flex-basis: 220px;
  flex-shrink: 0;
  flex-grow: 1;
}

.loadedToothChart {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.loadedToothChart .colorChart {
  /* ... */
}

.loadedToothChart .colorChart .colorChartSwatch {
  border: solid 2px #000;
  width: 22px;
  height: 18px;
  margin-right: 10px;
  flex-shrink: 0;
}

.loadedToothChart .colorChart .colorChartRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  font-size: 18px;
  margin-bottom: 6px;
}
.teethAndInfoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0.5vw;
}

.bsiInfoBlock {
  text-align: left;
}

.teethRows {
  margin-right: 10%;
}

.Procedure {
  flex-grow: 4;
  flex-basis: 25%;
}

.States,
.Equipment {
  flex-basis: 50%;
  flex-shrink: 1;
  flex-grow: 1;
}

.Comments {
  flex-grow: 1;
  flex-basis: 25%;
}

.roomBox {
  margin: 0.5vw;
  border: 0.05em solid black;
  border-radius: 0.08em;
  background-color: white;
  padding: 0.5vw;
}

.sykepleier .roomBox {
  /*flex-grow: 1;*/
}

.equipment,
.state,
.procedure {
  text-align: left;
  margin-left: 5%;
  font-weight: 300;
  font-size: 0.8em;
  line-height: 1.5em;
}

.comment {
  font-weight: 300;
  font-size: 0.7em;
  line-height: 1.5em;
  margin-bottom: 1em;
}

.stateList,
.equipmentList,
.procedureList {
  list-style-type: disc;
  margin-left: 0px;
  padding-left: 15px;
}

.commentList {
  list-style-type: none;
  padding-left: 0em;
}

.writeDate {
  margin-top: 0.4em;
  margin-bottom: 0.2em;
  margin-left: 0;
  margin-right: 0;
  font-size: 0.85em;
}

li {
  pointer-events: auto;
}

.immovable svg {
  /*pointer-events: none;*/
}

@media screen and (max-width: 770px) {
  .sykepleier.App,
  .sykepleier .Room,
  .sykepleier .col0 {
    height: auto;
    overflow: initial;
  }

  .sykepleier.App {
    width: 100%;
  }

  .sykepleier .Room {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 100vh;
  }

  .sykepleier .row {
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
  }

  .sykepleier .col2 {
    flex-basis: initial;
    flex-grow: 1;
  }

  .sykepleier .row2 {
    flex-wrap: wrap;
    flex-basis: 350px;
    flex-grow: 2;
    flex-shrink: 1;
  }

  .sykepleier .loadedToothChart {
    position: relative;
    height: 300px;
  }

  .sykepleier .roomBoxScrollableContent {
    position: relative;
    height: auto;
    overflow-y: initial;
  }

  .sykepleier .sToothChart {
    flex-basis: initial;
  }

  .sykepleier .row,
  .sykepleier .col1,
  .sykepleier .col2 {
    display: block;
  }
}

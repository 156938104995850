.Information {
  width: 100%;
  height: 100%;
  color: black;
  align-items: center;
  text-align: left;
}

.flexCol {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.infoSsection {
  margin: 2vw;
  padding: 2vw;
  padding-left: 4vw;
  padding-right: 4vw;
  border: 0.05em solid black;
  border-radius: 0.08em;
  background-color: white;
  padding: 0.5vw;
  width: fit-content;
}

.imgSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 60vw;
}

.imgContainer {
  flex-basis: 100%;
  flex-shrink: 1;
  padding: 10px;
}

.imgContainer img {
  width: 100%;
  height: auto;
  max-width: 500px;
}

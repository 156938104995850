input[type="checkbox"] {
    margin-right: 1vmin;
}

.TEQText,
.TEQNumber {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 50%;
}

.TEQCheckbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    overflow: hidden;
}

.TEQ {
    margin: 1vmin;
    padding-left: 3vmin;
    padding-right: 3vmin;
    border-radius: 1vmin;
    border: 0.4vmin solid black;
    pointer-events: auto;
    background: white;
}

.checked,
.filled {
    border: 0.4vmin solid #2862A0;
    background: #2862A0;
    color: white;
}

.mixed {
    border: none;
    background: repeating-linear-gradient( 45deg, #BED6F0, #BED6F0 10px, white 10px, white 20px);
}

input[type="text"],
input[type="number"] {
    margin-bottom: 1vmin;
}
.floors {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}

.floor {
    font-size: 1em;
    margin-top: 2vh;
}
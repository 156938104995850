.Tooth {
  width: 100%;
  max-width: 6.15%;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  object-fit: contain;
}

button {
  margin: 0;
  padding: 0;
  display: inline-block;
  pointer-events: none;
}

img {
  width: 10vmin;
}

svg {
  pointer-events: auto;
}

.sykepleier .Tooth svg {
  width: 100%;
  height: 100%;
}

button:focus {
  outline: 0;
}

html,
body,
#root,
.App,
.full {
    height: 100%;
}

.App {
    text-align: center;
    vertical-align: middle;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: black;
    width: 100vw;
    margin: 0;
    position: relative;
    font-weight: 350;
    overflow: auto;
}

.fullWidth {
    width: 100%;
}

.nobr {
    display: inline;
}

.upsideDown {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.mirrored {
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
}

.upsideDownMirrored {
    -moz-transform: rotate(180deg) scale(-1, 1);
    -webkit-transform: rotate(180deg) scale(-1, 1);
    -o-transform: rotate(180deg) scale(-1, 1);
    -ms-transform: rotate(180deg) scale(-1, 1);
    transform: rotate(180deg) scale(-1, 1);
}

.containerDiv {
    height: 100%;
    width: 100%;
    margin: 0;
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.95);
    -webkit-backdrop-filter: grayscale(1);
    backdrop-filter: grayscale(1);
}

.TCCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -45%);
}

.invisible {
    display: none;
    margin: 0;
}

.visible {
    opacity: 1;
}

.invisibleFade {
    opacity: 0;
    transition-duration: 0.2s;
    -webkit-transition-duration: 0.2s;
    -moz-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    -ms-transition-duration: 0.2s;
}

.visibleFade {
    opacity: 1;
    -webkit-transition: max-height 3s ease-out, opacity 0.4s ease 0.7s;
    -moz-transition: max-height 3s ease-out, opacity 0.4s ease 0.7s;
    -o-transition: max-height 3s ease-out, opacity 0.4s ease 0.7s;
    transition: max-height 3s ease-out, opacity 0.4s ease 0.7s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    60% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes smoothFade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.submitted {
    opacity: 0;
    transform: translate(0%, -3600%);
    transition-duration: 4s;
}

.submitting {
    opacity: 0.6;
    transform: translate(0%, -1500%);
    transition-duration: 30s;
}

.defaultPlacement {
    opacity: 1;
    transition-duration: 6s;
}

.Room .defaultPlacement,
.Room .TeethRow {
    transition-property: none;
}

h1 {
    font-size: 1.25em;
    font-weight: 500;
    white-space: nowrap;
}

h2 {
    font-size: 0.85em;
    font-weight: 400;
    white-space: nowrap;
}

h3 {
    font-size: 0.7em;
    font-weight: 350;
    white-space: nowrap;
}

h4 {
    font-weight: 315;
    font-size: 1em;
}

h5 {
    font-weight: 280;
    font-size: 0.8em;
    margin: 0;
}

.infoForPrintOut {
    visibility: hidden;
}

.bButton {
    background: none;
    color: black;
    padding: 1%;
    margin-right: 0.5em;
    margin-left: 0.5em;
    margin-bottom: 0.5em;
    font-size: 0.7em;
    border-radius: 1vmin;
    border: 0.3vmin solid black;
    pointer-events: auto;
    white-space: nowrap;
    overflow: hidden;
}

.primary {
    color: white;
    background: #208DE4;
    border: 0.3vmin solid #208DE4;
}

label:hover,
label:focus-within,
label:active,
input:hover+label,
input:active+label {
    border: 0.4vmin solid cornflowerblue;
    text-decoration: underline;
    text-decoration-color: cornflowerblue;
}

@media only screen and (max-width: 1000px) {
    h1 {
        font-size: 3.7vw;
    }
    h2 {
        font-size: 0.9em;
        white-space: nowrap;
    }
    h3 {
        font-size: 0.45em;
        white-space: nowrap;
    }
}

@media only screen and (max-height: 400px) {
    h1 {
        font-size: 6vh;
    }
    h2 {
        font-size: 6vh;
        white-space: nowrap;
    }
    .bButton {
        font-size: 0.6em;
    }
}

@media only screen and (max-width: 550px) {
    .bButton {
        font-size: 0.3vw;
    }
    h2 {
        font-size: 0.6em;
        white-space: nowrap;
    }
}

@media only screen and (max-width: 450px) {
    h1 {
        font-size: 3.2vw;
    }
}

@media print {
    @page {
        size: landscape
    }
    h1,
    h2,
    h3,
    h4,
    .BottomBar,
    .Info,
    label,
    input[type="checkbox"] {
        display: none !important
    }
    h5 {
        color: black;
    }
    body {
        background-color: white
    }
    .infoForPrintOut {
        visibility: visible;
        white-space: pre-line;
    }
    .TeethRow,
    .ToothEditorMenu {
        overflow: visible;
    }
}

.left {
    float: left;
}

.middle {
    float: none;
}

.right {
    float: right;
}

::selection {
    background: none
}

 ::-moz-selection {
    background: none
}
.BottomBar {
    position: absolute;
    bottom: 0%;
    text-align: right;
    width: 100%;
    pointer-events: none;
}

.bRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.centerTipsButton {
    position: absolute;
    background: white;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.squareButton:hover,
.squareButton:focus-within,
.squareButton:active,
input:hover+.squareButton,
input:active+.squareButton {
    border-color: cornflowerblue;
    text-decoration: underline;
    text-decoration-color: cornflowerblue;
}

.primary:hover,
.primary:focus-within,
.primary:active,
input:hover+.primary,
input:active+.primary {
    text-decoration: underline;
    text-decoration-color: white;
}
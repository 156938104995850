.Legend {
    bottom: 0.5em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-bottom: 5vh;
}

.legendItem {
    margin: 1%;
    margin-left: 2%;
    margin-right: 2%;
    padding: 1%;
    background: none;
    border: none;
    pointer-events: auto;
    font-size: 0.8em;
}
.TeethChart {
    width: 100%;
    max-width: 225vh;
    height: 100%;
    overflow: hidden;
}

.TeethChartLoaded {
    height: 100%;
    width: 100%;
}

.flexCenter {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transition-duration: 1s;
    -webkit-transition-duration: 1s;
    -moz-transition-duration: 1s;
    -o-transition-duration: 1s;
    -ms-transition-duration: 1s;
}

.flexSpaceBetween {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

.TeethRow {
    position: relative;
    width: 116%;
    transition-duration: 1s;
    -webkit-transition-duration: 1s;
    -moz-transition-duration: 1s;
    -o-transition-duration: 1s;
    -ms-transition-duration: 1s;
}

.sykepleier .TeethRow {
    width: 100%;
    
    /*
    Align center so that the teeth don't stretch vertically; this causes
    issues with overlapping buttons and svg's intermittently changing position.
    */
    align-items: center;
}

.sykepleier .teethRows {
    margin-right: 0;
}

.Tooth {
    margin-left: -8%;
    margin-right: -8%;
}

.sykepleier .Tooth {
    margin-left: 0;
    margin-right: 0;
}

.infoForPrintOut {
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 80%;
    margin-top: 3%;
    color: black;
    font-size: 3.5vh;
}

.upperMoved {
    margin-top: 12vh;
}

@media only screen and (max-width: 1500px) {
    .lower {
        margin-top: 3vh
    }
    .lowerMoved {
        margin-top: 3vh
    }
}

@media only screen and (max-width: 1200px) {
    .lower {
        margin-top: 8vh
    }
    .lowerMoved {
        margin-top: 8vh
    }
    .lower.immovable {
        margin-top: 3vh;
    }
}

@media only screen and (max-width: 700px) {
    .lower {
        margin-top: 10vh
    }
    .lowerMoved {
        margin-top: 10vh
    }
}

@media only screen and (max-width: 400px) {
    .lower {
        margin-top: 16vh
    }
    .lowerMoved {
        margin-top: 16vh
    }
}
.ToothEditor {
    width: 100%;
    text-align: center;
    justify-content: center;
    pointer-events: none;
}

#ToothEditorMenu {
    overflow: hidden;
    transition: flex 3s ease-out;
    transition-duration: 5s;
    -webkit-transition-duration: 5s;
    -moz-transition-duration: 5s;
    -o-transition-duration: 5s;
    -ms-transition-duration: 5s;
}

.ToothEditorForm {
    width: 80%;
    margin-left: 10%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

@media only screen and (max-width: 900px) {
    .ToothEditorForm {
        width: 100%;
        margin-left: 0%;
    }
}

@media only screen and (max-width:1000px) {
    .ToothEditorForm {
        width: 90%;
        margin-left: 5%;
    }
}

.ToothStateChoice {
    padding: 3vmin;
    border-radius: 0.5vmin;
    white-space: nowrap;
    margin: 1vmin;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 0;
}

.toothStatus {
    margin: 1.5%;
    margin-bottom: 0.5%;
    width: 97%;
    word-wrap: break-word;
    white-space: initial;
}

.centerInstructions {
    margin-top: -0.25%;
}

a {
    margin-top: 10vh;
}

button {
    margin-top: 9vh;
}

label:hover,
label:active,
input:hover+label,
input:active+label {
    background: #9ecdff;
    color: black;
}
* {
    box-sizing: border-box;
}

body {
    height: 100%;
    width: 100%;
    margin: 0;
    background-color: #f5f5f5;
    overflow: hidden;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    overflow: auto;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

::selection {
    background: blue
}

 ::-moz-selection {
    background: blue
}
.dataButtons {
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tt {
    margin-top: 5vh;
}

.copyInfo {
    color: black;
    font-size: 1em;
}
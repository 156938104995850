.topBarItem {
    pointer-events: auto;
    margin: 1vw;
    margin-bottom: 0;
    padding-bottom: 0;
    margin-top: 0;
    padding-top: 0;
    font-size: 0.8em;
    height: 100%;
    background: 0;
    border: 0em;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
}

.right {
    justify-content: right;
}

.left {
    justify-content: left;
}

.buttonIcon {
    margin-right: 1vw;
}

.topBarItem:hover,
.topBarItem:focus-within,
.squareButton:active,
input:hover+.squareButton,
input:active+.topBarItem {
    text-decoration: underline;
    text-decoration-color: white;
}

.topBar {
    margin: 0;
    position: absolute;
    z-index: 1;
    top: 0;
    overflow: visible;
    width: 100%;
    height: 3em;
    pointer-events: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    color: white;
    background-color: #2A2859;
}

#menu {
    background: white;
    color: black;
    pointer-events: auto;
    position: absolute;
    right: 0;
    top: 3em;
    border-left: 0.05em solid black;
    height: calc(100vh - 3em);
    overflow: auto;
    background-color: #f5f5f5;
    z-index: 3;
    max-width: 70%;
    min-width: 30%;
}

#menuList {
    list-style-type: none;
    text-align: left;
    padding-left: 0;
    overflow: hidden;
}

.menuItem {
    margin: 0;
}

.menuLink {
    text-decoration: none;
    color: black;
    display: block;
    margin: 1vw;
    margin-left: 0;
    padding: 1vw;
}

#menuTitle,
.menuTitle {
    text-align: left;
    padding-left: 2vw;
    margin: 20px 0;
}

.menuBackButton {
    text-align: left;
    padding-left: 2vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 1.25em;
    font-weight: 500;
    background: none;
    border: none;
    pointer-events: all;
    cursor: pointer;
}
.pageTitleContainer {
  display: flex;
  flex-direction: column;
  z-index: 2;
  justify-content: center;
  align-items: center;
  height: 3em;
}

.pageSubtitle {
  color: white;
  position: relative;
  font-size: 0.85em;
  line-height: 0.9;
}

/**
 * Page title is only inside .pageTitleContainer on pages where there is a
 * subtitle as well. For example room with "Siste tannundersøkelse DD.MM.YY"
 */
.pageTitleContainer .pageTitle {
  padding-top: 0;
  padding-bottom: 0;
  height: auto;
  line-height: 0.85;
}

.pageTitle {
  color: white;
  position: relative;
  z-index: 2;
  height: 1.8em;
  pointer-events: none;
  margin-top: 0;
  font-size: 1.8em;
  margin-bottom: 0;
  padding-top: 0.25em;
  width: fit-content;
}

.centerChildrenHorizontaly {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.subtitle {
  font-size: 1.1em;
  padding-top: 0.2em;
  color: black;
}

.h2 {
  font-size: 0.85em;
}

.h3 {
  font-size: 0.775em;
}

.h4 {
  font-size: 0.7em;
}

.squareButton {
  pointer-events: auto;
  background-color: #2a2859;
  color: white;
  margin: 1.5vw;
  padding: 1vw;
  padding-left: 3.5vw;
  padding-right: 3.5vw;
  margin-top: 0;
  font-size: 1.3em;
}

.disabled {
  border: 0.3vmin solid grey;
  background-color: lightgray;
  color: black;
}

.disabled:hover,
.disabled:active {
  border: 0.3vmin solid darkgrey;
  text-decoration: underline;
  text-decoration-color: darkgrey;
}
